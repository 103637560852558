import "./statusBadge.css";

export type Status = "Available" 
| "Preparing" 
| "Charging" 
| "SuspendedEVSE" 
| "SuspendedEV" 
| "Finishing" 
| "Unavailable" 
| "Faulted" 
| "Unknown" 
| "Downloading"
| "Offline"
| "RebootAfterReset"
| undefined;

type StatusBadgeProps = {
  status: Status;
  showStandardName?: boolean;
};

export default function StatusBadge({
  status,
  showStandardName = false
}: StatusBadgeProps) {
  switch (status) {
  case "Available":
    return <span className="status-badge-available">Available</span>;
  case "Preparing":
    return <span className="status-badge-preparing">{showStandardName ? "Preparing" : "Waiting for Auth"}</span>;
  case "Charging":
    return <span className="status-badge-charging">Charging</span>;
  case "SuspendedEVSE":
    return <span className="status-badge-suspendedEVSE">Suspended EVSE</span>;
  case "SuspendedEV":
    return <span className="status-badge-suspendedEV">Suspended EV</span>;
  case "Finishing":
    return <span className="status-badge-finishing">{showStandardName ? "Finishing" : "Unplug Cable"}</span>;
  case "Unavailable":
    return <span className="status-badge-unavailable">Unavailable</span>;
  case "Faulted":
    return <span className="status-badge-faulted">Faulted</span>;
  case "Downloading":
    return <span className="status-badge-downloading">Downloading</span>;
  case "Offline": 
    return <span className="status-badge-offline">Offline</span>;
  case "RebootAfterReset":
    return <span className="status-badge-rebootAfterReset">Rebooting</span>;
  default:
    return <span className="status-badge-unknown">Unknown</span>;
  }
}